import * as Turbo from '@hotwired/turbo';
import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
import { Alpinejs, appContainer } from '@js/container';
import { ErrorMessage } from '@js/components/errorMessage';

declare global {
  interface Paid {
    getFRDKey: (callback: (key: string) => void) => void;
  }
  interface Window {
    Alpine: Alpinejs | null;
    startAlpine: () => void;
    google: typeof google;
    mapLoaded: () => void;
    INTERN: boolean;
    _paid: Paid;
  }
}

function registComponents() {
  customElements.define('error-message', ErrorMessage);
}
registComponents();

Turbo.setProgressBarDelay(200);
Turbo.start();

const alpine: Alpinejs = Alpine as unknown as Alpinejs;
window.Alpine = alpine;

const isIntern = INTERN;
appContainer.setApp({ turbo: Turbo, alpine, mode: isIntern ? 'intern' : 'spojob' });

(async () => {
  if (appContainer.mode === 'intern') {
    const module = await import('@js/worker/intern/alpineController');
    new module.AlpineController();
  } else {
    const module = await import('@js/worker/alpineController');
    new module.AlpineController();
  }
})();